@tailwind base;
@tailwind components;
@tailwind utilities;
/* Gradient Border Effect */
.animate-starlight-right {
    animation: starlight-right 4s cubic-bezier(.6,.6,0,1) infinite;
}
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;700&display=swap');
@media (max-width: 768px) {
    .feature-height{
        width: 700px;
        height: 400px;
    }
  }
  @keyframes spin-slow {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }



  /* responsive for features section */
  @media (max-width: 640px) {
    .mobile-navbar, .mobile-feature{
      display: block;
    }
    .desktop-navbar, .desktop-feature{
      display: none;
    }
  }
  @media (min-width: 640px) {
    .mobile-navbar, .mobile-feature{
      display: block;
    }
    .desktop-navbar, .desktop-feature{
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    .mobile-navbar, .mobile-feature{
      display: none;
    }
    .desktop-navbar, .desktop-feature{
      display: flex;
    }
  }
  
  @media (min-width: 1024px) {
    
  }